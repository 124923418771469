import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import {
  ErrorMessage,
  InfoMessage,
  AsteriskText,
  Modal,
  ValidationTextField,
  Thumbnail
} from '../ui'
import { signInWithEmailLink } from './signInWithEmailLink'
// import { signInWithPassword } from './signInWithPassword'
import { sendSMSCode } from './sendSMSCode'
import { signInWithGoogle } from './signInWithGoogle'
import { ResetPasswordForm } from './ResetPasswordForm'
import { SubmitVerificationCodeForm } from './SubmitVerificationCodeForm'
import { activateRecaptcha } from './activateRecaptcha'
import { formatPhoneNumber } from './formatPhoneNumber'
import { confirmEmailAddressFormat } from './confirmEmailAddressFormat'
import { confirmPhoneNumberFormat } from './confirmPhoneNumberFormat'
import { Button, Grid, Typography } from '@material-ui/core'

/*
    This component contains the contents that are shown
    inside the box of the Sign-In Page - email address, password, etc.
*/
export const SignInForm = () => {
  const [emailAddress, setEmailAddress] = useState('')
  // const [passwordValue, setPasswordValue] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [infoMessage, setInfoMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [showPasswordResetModal, setPasswordResetModal] = useState(false)
  const [showSMSVerificationModal, setSMSVerificationModal] = useState(false)
  const [showCaptcha, setShowCatcha] = useState(false)
  const [confirmationResultValue, setConfirmationResultValue] = useState({
    example: () => console.log('This is the initial value of confirmationResultValue')
  })

  const history = useHistory()

  const onSignInWithEmailLinkClicked = async () => {
    console.log('CLICKED: Sign In with Email Link')
    try {
      const message = await signInWithEmailLink(emailAddress)
      setInfoMessage(message)
    } catch (e) {
      setErrorMessage(e.message)
    }
  }

  // const onSignInWithPasswordClicked = async () => {
  //   console.log('CLICKED: Sign In with Password')
  //   try {
  //     await signInWithPassword(emailAddress, passwordValue)
  //     history.push('/')
  //   } catch (e) {
  //     setErrorMessage(e.message)
  //   }
  // }

  const activateCaptchaAndSetPhoneNumber = phoneNumber => {
    try {
      setPhoneNumber(formatPhoneNumber(phoneNumber))
      if (showCaptcha === false) {
        activateRecaptcha('recaptcha-container')
        setShowCatcha(true)
        console.log('Recaptcha activated')
      }
    } catch (error) {
      console.error(error)
    }
  }

  let SMSSentFlag = false
  const onSignInWithSMSCodeClicked = async () => {
    console.log('CLICKED: Sign In with SMS Code')
    try {
      if (SMSSentFlag === false) {
        console.log('Sending Text Message...')
        const sendingPhoneNumber = '+1' + phoneNumber.replace(/[^0-9]/g, '').slice(-10)
        const confirmationResult = await sendSMSCode(sendingPhoneNumber)
        if (confirmationResult) {
          setInfoMessage(`Text Message Sent to ${sendingPhoneNumber}`)
          setConfirmationResultValue(confirmationResult)
          setSMSVerificationModal(true)
          SMSSentFlag = true
        } else {
          setErrorMessage('Something went wrong.')
        }
      } else {
        setSMSVerificationModal(true)
      }
    } catch (e) {
      console.error('Error in onSignInWithSMSCodeClicked:', e)
      setErrorMessage(e.message)
    }
  }

  const onSignInWithGoogleClicked = async () => {
    await signInWithGoogle()
    history.push('/')
  }

  const ThumbnailWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 10px 10px 10px;
`

  return (
    <div style={{ flexGrow: '1', overflowX: 'hidden' }}>
      <Grid container spacing={1} direction='row' justify='space-around' alignItems='center' style={{ marginLeft: '0px', marginTop: '10px', marginBottom: '10px', maxWidth: '100%' }}>
        <Grid item xs={12}>
          <ThumbnailWrap>
            <Thumbnail height='30vh' width='100%' url='https://i.imgur.com/qgfRqYb.jpg' />
          </ThumbnailWrap>

          <Typography variant='h3' align='center'>
          Welcome to Radiance in Eaux Claires
          </Typography>

          {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}

          {infoMessage ? <InfoMessage>{infoMessage}</InfoMessage> : null}

          <Button
            style={{ width: '100%', marginTop: '20px' }}
            variant='contained'
            color='primary'
            type='transparent'
            onClick={() => history.push('/signup')}
          >Create an account
          </Button>

          <Typography style={{ marginTop: '20px' }} variant='body1' align='center'>
          -- or Sign In Below --
          </Typography>

          <ValidationTextField
            style={{ marginTop: '20px' }}
            name='email'
            autocomplete='email'
            type='email'
            label='Email Address'
            variant='outlined'
            error={emailAddress ? !confirmEmailAddressFormat(emailAddress) : false}
            helperText={emailAddress ? (!confirmEmailAddressFormat(emailAddress) ? 'Please enter a valid email address.' : '') : ''}
            value={emailAddress}
            placeholder='someone@gmail.com'
            onChange={e => setEmailAddress(e.target.value)}
            onKeyDown={e => (e.key === 'Enter') ? onSignInWithEmailLinkClicked() : null}
          />

          <Button
            variant='contained'
            color='primary'
            disabled={!confirmEmailAddressFormat(emailAddress)}
            style={{ width: '100%', marginTop: '8px' }}
            onClick={onSignInWithEmailLinkClicked}
          >Sign In With Email Link
          </Button>

          {/* <ValidationTextField
            name='password'
            type='password'
            value={passwordValue}
            placeholder='Password'
            style={{ mmarginTop: '8px' }}
            onChange={e => setPasswordValue(e.target.value)}
            onKeyDown={e => (e.key === 'Enter') ? onSignInWithPasswordClicked() : null}
          />

          <Button
            variant='contained'
            color='primary'
            disabled={!confirmEmailAddressFormat(emailAddress) || (passwordValue.length < 8)}
            style={{ width: '100%', marginTop: '8px' }}
            onClick={onSignInWithPasswordClicked}
          >Sign In With Email & Password
          </Button>

          <Button
            type='transparent'
            style={{ width: '100%', marginTop: '8px' }}
            onClick={() => setPasswordResetModal(true)}
          >Forgot your password?
          </Button> */}

          <ValidationTextField
            style={{ marginTop: '20px' }}
            name='phone'
            autocomplete='tel'
            type='tel'
            label='Phone Number'
            placeholder='(780) 555-1234'
            required
            variant='outlined'
            error={phoneNumber ? !confirmPhoneNumberFormat(phoneNumber) : false}
            helperText={phoneNumber ? (!confirmPhoneNumberFormat(phoneNumber) ? 'Please enter a valid phone number.' : '') : ''}
            value={formatPhoneNumber(phoneNumber)}
            onChange={e => activateCaptchaAndSetPhoneNumber(formatPhoneNumber(e.target.value))}
            onKeyDown={e => (e.key === 'Enter') ? onSignInWithSMSCodeClicked() : null}
          />

          <Button
            variant='contained'
            color='primary'
            id='signInWithSMSCode'
            disabled={!(phoneNumber.length === 17)}
            style={{ width: '100%', marginTop: '8px' }}
            onClick={onSignInWithSMSCodeClicked}
          >Sign In With Text Message
          </Button>
          <AsteriskText>*Standard SMS Rates Apply</AsteriskText>
          <div id='recaptcha-container' />
          <Button
            variant='outlined'
            color='primary'
            style={{ width: '100%', marginTop: '14px' }}
            onClick={onSignInWithGoogleClicked}
          >Sign In With Google
          </Button>
        </Grid>
      </Grid>

      <Modal
        isOpen={showPasswordResetModal}
        onRequestClose={() => setPasswordResetModal(false)}
      >
        <ResetPasswordForm onClose={() => setPasswordResetModal(false)} />
      </Modal>

      <Modal
        isOpen={showSMSVerificationModal}
        onRequestClose={() => setSMSVerificationModal(false)}
        onAfterOpen={confirmationResultValue}
      >
        <SubmitVerificationCodeForm confirmationResult={confirmationResultValue} onClose={() => setSMSVerificationModal(false)} />
      </Modal>
    </div>
  )
}
