import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Button,
  FormControlLabel,
  Typography,
  RadioGroup,
  FormLabel,
  FormHelperText,
  Grid,
  Tooltip,
  Checkbox
} from '@material-ui/core'
import {
  ErrorMessage,
  ValidationTextField,
  // CustomTextField,
  ValidationRadio,
  Modal,
  InfoMessage,
  OwnerCodeModal,
  Thumbnail
} from '../ui'
// import { signOut } from './signOut'
import { formatPhoneNumber } from './formatPhoneNumber'
import { confirmEmailAddressFormat } from './confirmEmailAddressFormat'
import { confirmPhoneNumberFormat } from './confirmPhoneNumberFormat'
// import { verifyOwnerCode } from './verifyOwnerCode'
import { postalCodeEntry } from './postalCodeEntry'
import { getTempUserData } from './getTempUserData'
import { Autocomplete } from '@material-ui/lab'

export const SignupForm = () => {
  // All these different state variables are how we usually
  // keep track of form input values in React
  const [errorMessage, setErrorMessage] = useState('')
  const [infoMessage, setInfoMessage] = useState('')
  const [emailAddressEditable, setEmailAddressEditable] = useState(true)
  const [phoneNumberEditable, setPhoneNumberEditable] = useState(true)
  const [buildingIdEditable, setBuildingIdEditable] = useState(true)
  // const [password, setPassword] = useState('')
  // const [confirmPassword, setConfirmPassword] = useState('')
  const [unitNumberEntry, setUnitNumberEntry] = useState('')
  const [ownerCode, setOwnerCode] = useState('')
  const [ownerCodeError, setOwnerCodeError] = useState('')
  const [nonResidentOwnerMailingAddress, setNonResidentOwnerMailingAddress] = useState({
    streetAddress: '',
    city: '',
    province: '',
    postalCode: ''
  })
  const [agreeToTOS, setAgreetoTOS] = useState(false)
  const [showOwnerCodeModal, setShowOwnerCodeModal] = useState(false)
  const [showToSModal, setShowToSModal] = useState(false)
  const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState(false)
  const [userInfo, setUserInfo] = useState({
    emailAddress: '',
    emailVerified: false,
    firstName: '',
    lastName: '',
    unitNumber: '',
    buildingId: '',
    buildingName: '',
    propertyName: '',
    phoneNumber: '',
    phoneNumberVerified: false,
    userRole: '',
    isResident: ''
  })
  const [createAccountDisabled, setCreateAccountDisabled] = useState(false)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const userId = urlParams.get('id')
    if (userId) {
      getTempUserData(userId)
        .then(tempUserData => {
          if (tempUserData) {
            if (!tempUserData.phoneNumber) {
              tempUserData.phoneNumber = ''
              tempUserData.phoneNumberVerified = false
              setPhoneNumberEditable(true)
            } else {
              tempUserData.phoneNumberVerified = true
              setPhoneNumberEditable(false)
            }

            if (!tempUserData.emailAddress) {
              tempUserData.emailAddress = ''
              tempUserData.emailVerified = false
              setEmailAddressEditable(true)
            } else {
              tempUserData.emailVerified = true
              setEmailAddressEditable(false)
            }

            if (!tempUserData.buildingId) {
              tempUserData.buildingId = ''
              tempUserData.buildingName = ''
              tempUserData.propertyName = ''
              setBuildingIdEditable(true)
            } else {
              setBuildingIdEditable(false)
            }
          }
          setUserInfo({
            ...tempUserData,
            firstName: '',
            lastName: '',
            unitNumber: '',
            userRole: '',
            isResident: ''
          })
        })
        .catch(error => {
          console.error(error)
          setErrorMessage('Please enter your information.')
          setUserInfo({
            ...userInfo,
            emailVerified: false,
            phoneNumberVerified: false
          })
          setPhoneNumberEditable(true)
          setEmailAddressEditable(true)
          setBuildingIdEditable(true)
        })
    }
  }, [])

  // We can use the "useHistory" hook to change the browser
  // URL programmatically, such as after some async operation completes
  // const history = useHistory()

  const buildings = [
    {
      buildingName: '--',
      buildingId: '',
      units: '',
      propertyName: ''
    },
    // {
    //   buildingName: 'Building A',
    //   buildingId: '',
    //   units: ''
    //   propertyName: 'Radiance in Eaux Claires'
    // },
    {
      buildingName: '9519 160 Avenue NW - Building B',
      buildingId: 'JV22YSIRtzqGwEvmt2o5',
      units: [
        '101', '102', '103', '104', '105', '106', '107', '108', '109', '110', '111', '112',
        '201', '202', '203', '204', '205', '206', '207', '208', '209', '210', '211', '212', '213',
        '301', '302', '303', '304', '305', '306', '307', '308', '309', '310', '311', '312', '313',
        '401', '402', '403', '404', '405', '406', '407', '408', '409', '410', '411', '412', '413'
      ],
      propertyName: 'Radiance in Eaux Claires'
    }
    // {
    //   buildingName: 'Building C',
    //   buildingId: '',
    //   units: '',
    //   propertyName: 'Radiance in Eaux Claires'
    // },
    // {
    //   buildingName: 'Building D',
    //   buildingId: '',
    //   units: '',
    //   propertyName: 'Radiance in Eaux Claires'
    // },
    // {
    //   buildingName: 'Townhomes',
    //   buildingId: '',
    //   units: '',
    //   propertyName: 'Radiance in Eaux Claires'
    // }
  ]

  // A helper function that makes sure all the fields
  // are filled out correctly
  const validateForm = () => {
    // if required fields are missing
    if (!userInfo.firstName || !userInfo.lastName ||
      !userInfo.emailAddress || !userInfo.phoneNumber ||
      !userInfo.buildingId || !userInfo.unitNumber ||
      !userInfo.userRole || (userInfo.userRole === 'owner' ? !ownerCode : false) ||
      (userInfo.userRole === 'owner' && userInfo.isResident === null) ||
      (userInfo.userRole === 'owner' && userInfo.isResident === false ? !nonResidentOwnerMailingAddress.streetAddress ||
      !nonResidentOwnerMailingAddress.city || !nonResidentOwnerMailingAddress.province ||
      !nonResidentOwnerMailingAddress.postalCode : false)) return 'Please fill out all required fields.'
    if (!confirmEmailAddressFormat(userInfo.emailAddress)) return 'Please enter a valid email address.'
    if (!confirmPhoneNumberFormat(userInfo.phoneNumber)) return 'Please enter a valid phone number.'
    if (!!ownerCodeError || (ownerCode ? (ownerCode.length !== 6) : false)) return 'Your owner code is incorrect. If you did not receive one, call the Braden Equities office at (780) 429-5956 Mon–Fri 8:00am - 4:30pm'
    if ((userInfo.userRole === 'owner' && userInfo.isResident === false) ? nonResidentOwnerMailingAddress.postalCode.length !== 7 : false) return 'Please enter a valid postal code.'
    // if (password) { if (password.length < 8) return 'Passwords must be at least 8 characters.' }
    // if (password) { if (password !== confirmPassword) return 'Passwords must match.' }
    return null
  }

  // Here's the function that will be called when the user
  // clicks the "Create Account" button.
  const onClickCreateAccount = async () => {
    setErrorMessage('')

    // If there are any validation errors, show an error
    // and do not proceed.
    const validationError = validateForm()
    if (validationError) {
      setErrorMessage(validationError)
      return
    }

    const newUserInfo = {
      // password: password,
      ...userInfo,
      nonResidentOwnerMailingAddress
    }
    setInfoMessage('Registering new account...')
    await fetch('/createAccount', {
      method: 'POST',
      body: JSON.stringify({ newUserInfo }),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => {
      setInfoMessage('')
      if (res.status === 200) {
        setCreateAccountDisabled(true)
        if (newUserInfo.emailVerified === false && newUserInfo.phoneNumberVerified === false) {
          setInfoMessage('Account created! Please check your email and phone for a confirmation link.')
        } else if (newUserInfo.phoneNumberVerified === false) {
          setInfoMessage('Account created! Please check your phone for a confirmation link.')
        } else if (newUserInfo.emailVerified === false) {
          setInfoMessage('Account created! Please check your inbox for a confirmation email.')
        }
      } else {
        console.error(res)
        setErrorMessage('Something went wrong creating the account. Please try again later.')
      }
    })
      .catch(err => {
        console.error(err)
        setErrorMessage('Something went wrong creating the account. Please try again later.')
      })

    // await signOut()
    // history.push('/signin')
  }

  const ownerCodeEntryHandler = async (event) => {
    const ownerCode = event.target.value.replace(/[^0-9a-zA-Z]/g, '').toUpperCase()
    setOwnerCode(ownerCode)
    if (ownerCode.length < 2 && !!ownerCodeError) {
      setOwnerCodeError('')
    }

    if (ownerCode.length === 6) {
      setOwnerCodeError('Checking...')
      const ownerCodeInfo = {
        ownerCode,
        buildingId: userInfo.buildingId,
        unitNumber: userInfo.unitNumber
      }
      await fetch('/confirmOwnerCode', {
        method: 'POST',
        body: JSON.stringify({ ownerCodeInfo }),
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => {
        if (res.status === 200) {
          setOwnerCodeError('')
        } else {
          setOwnerCodeError('Incorrect code.')
        }
      })
        .catch(error => {
          console.error('Error verifying owner code', error)
          setOwnerCodeError('System dysfunction.')
        })
    }
  }

  const unitNumberHandler = value => {
    setUserInfo({ ...userInfo, unitNumber: value, ownerCode: '' })
  }

  const buildingIdHandler = event => {
    const bId = event.target.value
    const bName = buildings[buildings.findIndex(item => item.buildingId === bId)].buildingName
    const pName = buildings[buildings.findIndex(item => item.buildingId === bId)].propertyName
    setUserInfo({
      ...userInfo,
      buildingId: bId,
      buildingName: bName,
      propertyName: pName,
      ownerCode: ''
    })
  }

  let ownerCodeModalHeight = '250px'
  if (window.innerWidth < 700) {
    ownerCodeModalHeight = '360px'
  } else if (window.innerWidth < 800) {
    ownerCodeModalHeight = '330px'
  } else if (window.innerWidth < 900) {
    ownerCodeModalHeight = '310px'
  }

  return (
    <div style={{ flexGrow: '1', overflowX: 'hidden' }}>
      <Grid container spacing={1} direction='row' justify='space-around' alignItems='center' style={{ marginLeft: '0px', maxWidth: '100%' }}>
        <Grid item xs={12} style={{ marginBottom: '20px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px' }}>
            <Thumbnail
              height='30vh'
              width='100%'
              url='https://i.imgur.com/qgfRqYb.jpg'
            />
          </div>

          <Typography variant='h3' align='center'>
          Welcome to Radiance in Eaux Claires
          </Typography>

          <Typography variant='h5' align='center'>
            --
            <br />
          Create an Account
          </Typography>

          {/* <HeadingSmall style={{ textAlign: 'center' }}>Create an Account</HeadingSmall> */}
        </Grid>

        <Grid item xs={6}>
          <ValidationTextField
            name='fname'
            autoComplete='given-name'
            type='text'
            label='First Name'
            required
            variant='outlined'
            value={userInfo.firstName === undefined ? '' : userInfo.firstName}
            placeholder='John'
            onChange={e => setUserInfo({ ...userInfo, firstName: e.target.value })}
          />
        </Grid>

        <Grid item xs={6}>
          <ValidationTextField
            name='lname'
            autoComplete='family-name'
            type='text'
            label='Last Name'
            required
            variant='outlined'
            value={userInfo.lastName === undefined ? '' : userInfo.lastName}
            placeholder='Doe'
            onChange={e => setUserInfo({ ...userInfo, lastName: e.target.value })}
          />
        </Grid>

        <Grid item xs={12}>
          <Tooltip enterDelay={0} enterTouchDelay={0} title={(!emailAddressEditable) ? 'Your email address was pre-verified and can\'t be edited' : ''}>
            <ValidationTextField
              name='email'
              autoComplete='email'
              type='email'
              label='Email Address'
              required
              variant='outlined'
              error={userInfo.emailAddress ? !confirmEmailAddressFormat(userInfo.emailAddress) : false}
              helperText={userInfo.emailAddress ? (!confirmEmailAddressFormat(userInfo.emailAddress) ? 'Please enter a valid email address.' : '') : ''}
              disabled={!emailAddressEditable}
              value={!userInfo.emailAddress ? '' : userInfo.emailAddress}
              placeholder='John.Doe@gmail.com'
              onChange={e => setUserInfo({ ...userInfo, emailAddress: e.target.value })}
            />
          </Tooltip>
        </Grid>

        <Grid item xs={12}>
          <Tooltip enterDelay={0} enterTouchDelay={0} title={(!phoneNumberEditable) ? 'Your phone number was pre-verified and can\'t be edited' : ''}>
            <ValidationTextField
              name='phone'
              autoComplete='tel'
              type='tel'
              label='Phone Number'
              placeholder='(780) 555-1234'
              required
              variant='outlined'
              error={userInfo.phoneNumber ? !confirmPhoneNumberFormat(userInfo.phoneNumber) : false}
              helperText={userInfo.phoneNumber ? (!confirmPhoneNumberFormat(userInfo.phoneNumber) ? 'Please enter a valid phone number.' : '') : ''}
              disabled={!phoneNumberEditable}
              value={!userInfo.phoneNumber ? '' : userInfo.phoneNumber}
              onChange={e => setUserInfo({ ...userInfo, phoneNumber: formatPhoneNumber(e.target.value) })}
            />
          </Tooltip>
        </Grid>

        <Grid item xs={12}>
          <Tooltip
            enterDelay={0} enterTouchDelay={0} title={(!buildingIdEditable)
              ? 'Your building cannot be edited.' : ''}
          >
            <FormControl variant='outlined' style={{ width: '100%', marginTop: '4px' }}>
              <InputLabel id='buildingSelector'>Building</InputLabel>
              <Select
                style={{ borderColor: '#219c37' }}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  }
                }}
                label='Building'
                labelId='buildingSelector'
                required
                variant='outlined'
                error={!userInfo.buildingId}
                disabled={!buildingIdEditable}
                value={!userInfo.buildingId ? '' : userInfo.buildingId}
                onChange={buildingIdHandler}
                // onBlur={}
              >
                {buildings.map((building) => (
                  <MenuItem key={building.buildingName} value={building.buildingId}>
                    {building.buildingName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Tooltip>
        </Grid>

        <Grid item xs={6}>
          <Tooltip enterDelay={0} enterTouchDelay={0} title={(!userInfo.buildingId) ? 'Please select your building' : ''}>
            <Autocomplete
              value={!userInfo.unitNumber ? '' : userInfo.unitNumber}
              onChange={(e, value) => setUserInfo({ ...userInfo, unitNumber: value, ownerCode: '' })}
              onInputChange={(e, value) => {
                setUnitNumberEntry(value)
              }}
              onKeyDown={(e) => (
                e.keyCode === 13 &&
                buildings[buildings.findIndex(item => item.buildingId === userInfo.buildingId)].units.includes(unitNumberEntry)
              ) ? unitNumberHandler(`${unitNumberEntry}`) : null}
              onBlur={() => buildings[buildings.findIndex(item => item.buildingId === userInfo.buildingId)].units.includes(unitNumberEntry)
                ? unitNumberHandler(`${unitNumberEntry}`) : null}
              id='unit-number-picker'
              options={userInfo.buildingId ? buildings[buildings.findIndex(item => item.buildingId === userInfo.buildingId)].units.concat('') : ['']}
              getOptionLabel={(option) => option}
              style={{ width: '100%' }}
              renderInput={(params) =>
                <ValidationTextField
                  {...params}
                  label='Unit Number'
                  variant='outlined'
                  error={userInfo.buildingId ? !userInfo.unitNumber : false}
                />}
              disabled={!userInfo.buildingId}
            />
          </Tooltip>
        </Grid>

        <Grid item xs={6}>
          <Tooltip enterDelay={0} enterTouchDelay={0} title={(!userInfo.buildingId) ? 'Please select your building' : ''}>
            <FormControl variant='outlined' style={{ width: '100%', marginTop: '4px' }}>
              <InputLabel id='roleSelector'>Relation to Unit</InputLabel>
              <Select
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  }
                }}
                label='Relation to Unit'
                labelId='roleSelector'
                required
                variant='outlined'
                error={!userInfo.userRole}
                disabled={!userInfo.buildingId}
                value={!userInfo.userRole ? '' : userInfo.userRole}
                onChange={e => setUserInfo({ ...userInfo, userRole: e.target.value })}
              >
                <MenuItem value=''>--</MenuItem>
                <MenuItem key='Rent' value='renter'>Renting</MenuItem>
                <MenuItem key='Own' value='owner'>Owner</MenuItem>
                <MenuItem key='Other' value='other'>Other</MenuItem>
              </Select>
            </FormControl>
          </Tooltip>
        </Grid>
      </Grid>

      {(userInfo.userRole === 'owner')
        ? <Grid container spacing={1} direction='row' justify='center' alignItems='center' style={{ marginLeft: '0px', maxWidth: '100%' }}>
          <Grid item xs={6}>
            <Tooltip enterDelay={0} enterTouchDelay={0} title={(!userInfo.unitNumber) ? 'Please select your unit number' : ''}>
              <ValidationTextField
                type='text'
                label='Owner Code'
                placeholder='A1B2C3'
                required={(userInfo.userRole === 'owner')}
                variant='outlined'
                error={!!ownerCodeError || (ownerCode ? (ownerCode.length !== 6) : false)}
                helperText={ownerCodeError}
                disabled={!userInfo.unitNumber}
                value={!ownerCode ? '' : ownerCode}
                onChange={ownerCodeEntryHandler}
              />
            </Tooltip>
            <Button
              style={{ width: '100%' }}
              // style={isResident === null ? { padding: '0px 0px 0px 4px' } : { padding: '0px 0px 0px 4px', marginTop: '-8px' }}
              size='small'
              variant='text'
              color='secondary'
              onClick={() => setShowOwnerCodeModal(true)}
            >
              No Owner Code?
            </Button>
          </Grid>

          <Grid item xs={6}>
            <FormControl style={{ width: '100%', textAlign: 'center', alignItems: 'center' }} component='fieldset'>
              <FormLabel style={userInfo.isResident === '' ? { color: '#e32727' } : { color: 'black' }} component='legend'>Do you live here?</FormLabel>
              <RadioGroup row aria-label='Resident Status' name='resident1' value={userInfo.isResident}>
                <FormControlLabel
                  control={
                    <ValidationRadio size='small' checked={userInfo.isResident === true} onClick={() => setUserInfo({ ...userInfo, isResident: true })} />
                  } label='Yes'
                />
                <FormControlLabel
                  control={
                    <ValidationRadio size='small' checked={userInfo.isResident === false} onClick={() => setUserInfo({ ...userInfo, isResident: false })} />
                  } label='No'
                />
              </RadioGroup>
              {userInfo.isResident === '' ? <FormHelperText style={{ color: '#e32727', marginTop: '-6px' }}>Response required*</FormHelperText> : null}
            </FormControl>
          </Grid>
        </Grid>
        : null}

      {userInfo.isResident === false && userInfo.userRole === 'owner'
        ? <div>
          <Grid container spacing={1} direction='row' justify='center' alignItems='center' style={{ marginLeft: '0px', maxWidth: '100%' }}>
            <Grid item xs={12}>
              <ValidationTextField
                name='address'
                autoComplete='street-address'
                type='text'
                label='Your Street Address'
                placeholder='123 Evergreen Terrace NW'
                required={userInfo.isResident === false}
                variant='outlined'
                helperText=''
                value={nonResidentOwnerMailingAddress.streetAddress}
                onChange={e => setNonResidentOwnerMailingAddress({
                  ...nonResidentOwnerMailingAddress,
                  streetAddress: e.target.value
                })}
              />
            </Grid>

            <Grid item xs={5}>
              <ValidationTextField
                name='city'
                autoComplete='address-level2'
                type='text'
                label='City'
                placeholder='Edmonton'
                required={userInfo.isResident === false}
                variant='outlined'
                helperText=''
                value={nonResidentOwnerMailingAddress.city}
                onChange={e => setNonResidentOwnerMailingAddress({
                  ...nonResidentOwnerMailingAddress,
                  city: e.target.value
                })}
              />
            </Grid>

            <Grid item xs={3}>
              <FormControl variant='outlined' style={{ width: '100%', marginTop: '4px' }}>
                <InputLabel id='provinceSelector'>Prov.</InputLabel>
                <Select
                  name='province'
                  autoComplete='address-level1'
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left'
                    }
                  }}
                  label='Prov.'
                  labelId='provinceSelector'
                  required={userInfo.isResident === false}
                  error={!nonResidentOwnerMailingAddress.province}
                  variant='outlined'
                  value={nonResidentOwnerMailingAddress.province}
                  onChange={e => setNonResidentOwnerMailingAddress({
                    ...nonResidentOwnerMailingAddress,
                    province: e.target.value
                  })}
                >
                  <MenuItem key='provNull' value=''>--</MenuItem>
                  <MenuItem key='provAB' value='Alberta'>AB</MenuItem>
                  <MenuItem key='provBC' value='British Columbia'>BC</MenuItem>
                  <MenuItem key='provMB' value='Manitoba'>MB</MenuItem>
                  <MenuItem key='provNB' value='New Brunswick'>NB</MenuItem>
                  <MenuItem key='provNL' value='Newfoundland and Labrador'>NL</MenuItem>
                  <MenuItem key='provNS' value='Nova Scotia'>NS</MenuItem>
                  <MenuItem key='provNT' value='Northwest Territories'>NT</MenuItem>
                  <MenuItem key='provNU' value='Nunavut'>NU</MenuItem>
                  <MenuItem key='provON' value='Ontario'>ON</MenuItem>
                  <MenuItem key='provPE' value='Prince Edward Island'>PE</MenuItem>
                  <MenuItem key='provQC' value='Quebec'>QC</MenuItem>
                  <MenuItem key='provSK' value='Saskatchewan'>SK</MenuItem>
                  <MenuItem key='provYT' value='Yukon'>YT</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={4}>
              <ValidationTextField
                name='postal'
                autoComplete='postal-code'
                type='text'
                label='Postal Code'
                placeholder='A1A 1A1'
                required={userInfo.isResident === false}
                variant='outlined'
                error={nonResidentOwnerMailingAddress.postalCode ? nonResidentOwnerMailingAddress.postalCode.length !== 7 : false}
                helperText=''
                value={nonResidentOwnerMailingAddress.postalCode}
                onChange={e => setNonResidentOwnerMailingAddress({
                  ...nonResidentOwnerMailingAddress,
                  postalCode: postalCodeEntry(e.target.value)
                })}
              />
            </Grid>
          </Grid>
        </div>
        : null}

      {/* <Grid container spacing={1} direction='row' justify='center' alignItems='center' style={{ marginLeft: '0px', maxWidth: '100%', marginBottom: '0px' }}>
        <Grid item xs={12}>
          <CustomTextField
            name='password'
            autoComplete='new-password'
            type='password'
            label='Password (optional)'
            placeholder='optional'
            required={false}
            variant='outlined'
            error={password ? (password.length < 8) : false}
            helperText={password ? ((password.length < 8) ? 'Password must be at least 8 characters long.' : '') : ''}
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </Grid>
      </Grid>

      {(password && password.length > 7)
        ? <Grid container spacing={1} direction='row' justify='center' alignItems='center' style={{ marginLeft: '0px', maxWidth: '100%', marginBottom: '0px' }}>
          <Grid item xs={12}>
            <ValidationTextField
              name='confirm password'
              autoComplete='new-password'
              type='password'
              label='Confirm Password'
              placeholder='Please re-enter your password'
              required={!!password}
              variant='outlined'
              error={password ? (password !== confirmPassword) : false}
              helperText={confirmPassword ? ((password !== confirmPassword) ? 'Passwords do not match.' : '') : ''}
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
            />
          </Grid>
        </Grid>
        : null} */}

      <Grid container spacing={1} direction='row' justify='center' alignItems='center' style={{ marginLeft: '0px', maxWidth: '100%', marginBottom: '0px' }}>
        <Grid item xs={12} style={{ textAlign: 'center', alignItems: 'center' }}>
          <FormControlLabel
            style={{ marginLeft: '10px', marginRight: '10px' }}
            control={
              <Checkbox
                checked={agreeToTOS}
                onChange={e => setAgreetoTOS(e.target.checked)}
                name='Terms of Service and Privacy Policy'
                color='primary'
              />
            }
            label={
              <div>
                <span>I agree to the </span><Link to='/signup' onClick={() => setShowToSModal(true)}>Terms of Service</Link>
                <span> and </span><Link to='/signup' onClick={() => setShowPrivacyPolicyModal(true)}>Privacy Policy</Link>
                <span>.</span>
              </div>
            }
          />
        </Grid>
        {errorMessage ? <Grid item xs={12}> <ErrorMessage> {errorMessage} </ErrorMessage> </Grid> : null}
        {infoMessage ? <Grid item xs={12}> <InfoMessage> {infoMessage} </InfoMessage> </Grid> : null}

        <Grid item xs={12}>
          <Tooltip
            enterDelay={0} enterTouchDelay={0}
            placement='top'
            title={(!agreeToTOS) ? 'You must agree to the Terms of Service and Privacy Policy to use this service.' : ''}
          >
            <span>
              <Button
                disabled={!agreeToTOS || createAccountDisabled}
                variant='contained'
                color='primary'
                style={{
                  background: (!userInfo.firstName || !userInfo.lastName ||
                  !userInfo.emailAddress || !userInfo.phoneNumber ||
                  !userInfo.buildingId || !userInfo.unitNumber ||
                  !userInfo.userRole || (userInfo.userRole === 'owner' ? (!!ownerCodeError || (ownerCode ? (ownerCode.length !== 6) : false)) : false) ||
                  (userInfo.userRole === 'owner' && userInfo.isResident === null) ||
                  (userInfo.userRole === 'owner' && userInfo.isResident === false ? !nonResidentOwnerMailingAddress.streetAddress ||
                  !nonResidentOwnerMailingAddress.city || !nonResidentOwnerMailingAddress.province ||
                  !nonResidentOwnerMailingAddress.postalCode : false)) ||
              // (password ? (password.length < 8 || password !== confirmPassword) : false) ||
              !agreeToTOS ? '#ccc' : '#a4d22a',
                  width: '100%',
                  marginBottom: '10px'
                }}
                onClick={onClickCreateAccount}
              >Create Account
              </Button>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
      <OwnerCodeModal
        isOpen={showOwnerCodeModal}
        onRequestClose={() => setShowOwnerCodeModal(false)}
        style={{ height: ownerCodeModalHeight }}
      >
        <Grid item xs={12}>
          <Typography variant='body1'>
            <ul style={{ paddingLeft: '10px', marginRight: '-5px' }}>
              <li>We use Owner Codes to verify that you are the owner of this property, enabling you to carry out owner-only actions, such as submitting formal complaints.</li>
              <br />
              <li>You should have received your Owner Code by mailed letter or email (if on file). If you did not receive one, please call the Braden Equities office at <a href='tel:+17804295956'>(780) 429-5956</a> Mon–Fri 8:00am - 4:30pm.</li>
            </ul>
          </Typography>
        </Grid>
      </OwnerCodeModal>

      <Modal
        isOpen={showToSModal}
        onRequestClose={() => setShowToSModal(false)}
      >
        <span>This is a very very long Terms of Service. Super duper long!!!</span>
        {/* <TermsOfService onClose={() => setShowToSModal(false)} /> */}
      </Modal>

      <Modal
        isOpen={showPrivacyPolicyModal}
        onRequestClose={() => setShowPrivacyPolicyModal(false)}
      >
        <span>This is the privacy policy</span>
        {/* <PrivacyPolicy onClose={() => setShowPrivacyPolicyModal(false)} /> */}
      </Modal>
    </div>
  )
}
