export const formatPhoneNumber = (phoneNumber) => {
  phoneNumber = phoneNumber.replace(/\+1/g, '').replace(/[^0-9]/g, '')
  if (phoneNumber.length < 11) {
    if (phoneNumber.length > 0 && phoneNumber.length < 4) {
      phoneNumber = '+1 (' + phoneNumber
    } else if (phoneNumber.length > 3 && phoneNumber.length < 7) {
      phoneNumber = '+1 (' + phoneNumber.substring(0, 3) + ') ' + phoneNumber.substring(3)
    } else if (phoneNumber.length > 6) {
      phoneNumber = '+1 (' + phoneNumber.substring(0, 3) + ') ' + phoneNumber.substring(3, 6) + '-' + phoneNumber.substring(6)
    }
  } else if (phoneNumber.length === 11) {
    phoneNumber = '+' + phoneNumber.substring(0, 1) + ' (' + phoneNumber.substring(1, 4) + ') ' + phoneNumber.substring(4, 7) + '-' + phoneNumber.substring(7)
  } else if (phoneNumber.length > 14) {
    phoneNumber = phoneNumber.slice(-14)
  }
  return phoneNumber
}
