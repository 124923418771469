import React, { useState } from 'react'
import styled from 'styled-components'
import {
  Button,
  HeadingSmall,
  TextInput,
  ErrorMessage
} from '../ui'
import { submitSMSVerificationCode } from './submitSMSVerificationCode'

const FullWidthTextInput = styled(TextInput)`
    display: block;
    width: 100%;
    margin-bottom: 16px;
`

const FullWidthButton = styled(Button)`
    display: block;
    width: 100%;
`

/*
    This is the content for the modal that gets shown to users
    when they click "Forgot Password" on the Sign-In Page.
*/
export const SubmitVerificationCodeForm = ({ onClose, confirmationResult }) => {
  const [verificationCodeValue, setVerificationCode] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const onClickLogin = async () => {
    try {
      const user = await submitSMSVerificationCode(confirmationResult, verificationCodeValue)
      if (user) {
        onClose()
        return user
      } else {
        setErrorMessage('Something went wrong.')
      }
    } catch (error) {
      setErrorMessage(error)
    }
  }

  return (
    <div>
      {errorMessage ? <ErrorMessage> {errorMessage} </ErrorMessage> : null}
      <HeadingSmall>Enter Verification Code</HeadingSmall>
      <FullWidthTextInput
        type='number'
        onChange={e => setVerificationCode(e.target.value)}
        placeholder='Eg. 123456'
        onKeyDown={e => (e.key === 'Enter') ? onClickLogin() : null}
      />
      <FullWidthButton onClick={onClickLogin}>Login</FullWidthButton>
    </div>
  )
}
