import React from 'react'
import { styled } from '@material-ui/core/styles'
import Select from '@material-ui/core/Select'

const ValidationSelectInput = styled(Select)({
  margin: '16px 4px 4px 4px',
  '& input:valid + fieldset': {
    borderColor: 'green',
    borderWidth: 2
  },
  '& input:invalid + fieldset': {
    borderColor: 'red',
    borderWidth: 2
  },
  '& input:valid:focus + fieldset': {
    borderLeftWidth: 6,
    padding: '4px !important' // override inline-style
  }
})

const ValidSelectInput = ({ ...rest }, ref) => {
  return (
    <ValidationSelectInput
      type='text'
      {...rest}
      ref={ref}
    />
  )
}

export const ValidationSelectField = React.forwardRef(ValidSelectInput)
