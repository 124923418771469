import React from 'react'
import styled from 'styled-components'

const PageBackground = styled.div({
  backgroundColor: '#e5e5e5',
  display: 'flex',
  width: '100%',
  height: '100vh',
  justifyContent: 'center',
  margin: 'auto'
})

const ContentBox = styled.div({
  backgroundColor: '#fff',
  borderRadius: '8px',
  padding: '0px',
  margin: 'auto',
  width: '100vw',
  maxWidth: '500px'
})

export const CenteredContentBox = ({ children }) =>
  <PageBackground>
    <ContentBox>
      {children}
    </ContentBox>
  </PageBackground>
