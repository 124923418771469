import React from 'react'
import { styled } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

const TextInputStyle = styled(TextField)({
  width: '100%',
  margin: '4px 0px 0px 0px',
  '& input:invalid + fieldset': {
    borderColor: 'red',
    borderWidth: 1
  },
  '& input:valid:focus + fieldset': {
    borderLeftWidth: 6,
    padding: '4px !important' // override inline-style
  }
})

const TextInput = ({ ...rest }, ref) => {
  return (
    <TextInputStyle
      type='text'
      {...rest}
      ref={ref}
    />
  )
}

export const CustomTextField = React.forwardRef(TextInput)
