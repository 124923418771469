export const submitSMSVerificationCode = async (confirmationResult, verificationCode) => {
  try {
    let user = null
    await confirmationResult.confirm(verificationCode)
      .then(result => {
        console.log('Userdata obtained:', result.user)
        user = result.user
      })
    return user
  } catch (e) {
    console.error('Error in submitSMSVerificationCode:', e)
    throw new Error('Unable to Sign In. Please confirm the verification code was entered accurately.')
  }
}
