export const postalCodeEntry = (input) => {
  input = input.replace(/[^0-9a-zA-Z]/g, '').toUpperCase()
  if (input.length > 3) {
    input = input.substring(0, 3) + ' ' + input.substring(3)
  }
  const postalCodePattern = ['L', 'N', 'L', ' ', 'N', 'L', 'N']
  const postalCode = []
  for (var i = 0; i < input.length; i++) {
    if (postalCodePattern[i] === 'L') {
      postalCode[i] = input.charAt(i).replace(/[^A-Z]/g, '')
    }
    if (postalCodePattern[i] === 'N') {
      postalCode[i] = input.charAt(i).replace(/[^0-9]/g, '')
    }
    if (postalCodePattern[i] === ' ') {
      postalCode[i] = ' '
    }
  }

  return postalCode.join('')
}
