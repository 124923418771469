import firebase from 'firebase/app'

export const activateRecaptcha = (recaptchaContainer, callback) => {
  window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(recaptchaContainer, {
    size: 'invisible',
    callback: (response) => {
      // reCAPTCHA solved, allow signInWithPhoneNumber.
      callback()
    }
  })
  window.recaptchaVerifier.render()
}
