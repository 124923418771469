import firebase from 'firebase/app'

export const getTempUserData = async (userId) => {
  try {
    // console.log('function: getTempUserData')
    const store = firebase.firestore()
    const tempUserDoc = await store.collection('temporaryUsers')
      .doc(userId)
      .get()
    const tempUserData = tempUserDoc.data()
    return tempUserData
  } catch (error) {
    console.error('error in getTempUserData:', error)
    // throw new Error(error.message)
    return null
  }
}
