import React from 'react'
import { SignupForm } from './SignupForm'
import { CenteredContentBox } from '../ui'

/*
    This component basically just renders the Create Account Form
    inside of a nice-looking content box.
*/

export const SignupPage = () => {
  return (
    <>
      <CenteredContentBox>
        <SignupForm />
      </CenteredContentBox>
    </>
  )
}
