export const confirmEmailAddressFormat = (emailAddress) => {
  if (!(emailAddress.includes('@') && emailAddress.includes('.'))) {
    return false
  }

  const beforeAt = emailAddress.split('@')[0]
  if (beforeAt.length < 1) {
    return false
  }

  const afterAt = emailAddress.split('@')[1]
  if (afterAt.length < 1) {
    return false
  }

  if (!(afterAt.includes('.'))) {
    return false
  }

  if (afterAt.split('.')[0] < 1) {
    return false
  }

  if (afterAt.split('.')[1] < 1) {
    return false
  }

  return true
}
