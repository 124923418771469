import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import clsx from 'clsx'

import { Radio } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19, 189, 39,.6)',
      outlineOffset: 2
    },
    'input:hover ~ &': {
      backgroundColor: '#d5e6d9'
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)'
    }
  },
  checkedIcon: {
    backgroundColor: '#10ad44',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""'
    },
    'input:hover ~ &': {
      backgroundColor: '#0d9139'
    }
  }
})

const StyledRadio = ({ ...props }, ref) => {
  const classes = useStyles()

  return (
    <Radio
      className={classes.root}
      disableRipple
      color='default'
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      ref={ref}
      {...props}
    />
  )
}

export const ValidationRadio = React.forwardRef(StyledRadio)
