import firebase from 'firebase/app'

export const signInWithEmailLink = async (email) => {
  try {
    var actionCodeSettings = {
      // URL you want to redirect back to. The domain for this
      // URL must be whitelisted in the Firebase Console.
      url: 'https://localhost:3000',
      // This must be true.
      handleCodeInApp: true
    }

    await firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings)
      .then(function () {
      // The link was successfully sent. Inform the user.
      // Save the email locally so you don't need to ask the user for it again
      // if they open the link on the same device.
        window.localStorage.setItem('emailForSignIn', email)
        return 'Please Check Your Email'
      })
  } catch (e) {
    throw new Error(e.message)
  }
}
