import styled from 'styled-components'

export const AsteriskText = styled.p`
    font-size: 75%;
    font-weight: 100;
    margin-top: 0px;
    margin-bottom: 10px;
    padding: 0px;
    color: #808080
`
