import React from 'react'
import styled from 'styled-components'

const StyledInput = styled.input`
    border: 2px solid #ccc;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: bold;
    outline: none;
    padding: 16px;

    ::placeholder {
        color: #bababa;
    }
`

const TextInput = ({ ...rest }, ref) => {
  return (
    <StyledInput
      type='text'
      {...rest}
      ref={ref}
    />
  )
}

export const ForwardedTextInput = React.forwardRef(TextInput)
