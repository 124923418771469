import React from 'react'
import { LanderPage } from '../ui'

const SuccessMessage = () => (
  <LanderPage
    heading='Your email has been confirmed!'
    message='You can begin using the text line :)'
  />
)

const FailureMessage = () => (
  <LanderPage
    heading='Uh oh...'
    message={`
            It looks like something went wrong with your confirmation.
            If you waited a while since creating your account, you may have
            to create it again.
        `}
  />
)

const PhoneMessage = () => (
  <LanderPage
    heading='Your email has been confirmed!'
    message='We are still awaiting confirmation of your phone number. Please click the provided link on your phone.'
  />
)

const EmailMessage = () => (
  <LanderPage
    heading='Your email has been confirmed!'
    message='We are still awaiting confirmation of your email address. Please click the provided in the email sent to you.'
  />
)

/*
    This is the page/component that the user will land on
    when they attempt to confirm their email. We'll have Firebase
    send them to either the "success" or "failure" route, depending
    on whether or not their confirmation was successful.
*/
export const ConfirmationLanderPage = ({ success, phone, email }) => {
  if (success) {
    if (phone) {
      return <PhoneMessage />
    } else if (email) {
      return <EmailMessage />
    } else {
      return <SuccessMessage />
    }
  } else {
    return <FailureMessage />
  }
}
