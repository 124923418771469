import firebase from 'firebase/app'

export const sendSMSCode = async phoneNumber => {
  try {
    const appVerifier = await window.recaptchaVerifier
    let codeResult = {}
    await firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
      .then(confirmationResult => {
        console.log('confirmationResult:', confirmationResult)
        // window.confirmationResult = confirmationResult
        codeResult = confirmationResult
      })
      .catch(error => {
        console.error(error)
      })
    return codeResult
  // return {
  //   yay: () => console.log('this is a function as part of the sendSMS code object'),
  //   yay2: 'here is another part of the object',
  //   phoneNumber
  // }
  } catch (error) {
    console.error('Error in sendSMScode:', error)
    if (error.code.includes('too-many-requests')) {
      throw new Error('Too many attempts. ' + error.message)
    }
  }
}
