import React from 'react'
import styled from 'styled-components'
import ModalBase from 'react-modal'
import CloseIcon from '@material-ui/icons/Close'
import { IconButton } from '@material-ui/core'

const customStyles = {
  content: {
    minWidth: '270px',
    maxWidth: '450px',
    maxheight: 'calc(100vh - 10px)',
    margin: '0',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    borderRadius: '8px',
    zIndex: '1001',
    padding: '10px'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    alignItems: 'center',
    zIndex: '1000'
  }
}

const ContentBox = styled.div`
    background-color: #fff;
    padding: 8px;
`

ModalBase.setAppElement('#modal')

const Modal = ({
  isOpen,
  afterOpenModal = () => {},
  onRequestClose = () => {},
  children
}, ref) =>
  <ModalBase
    ref={ref}
    appElement={document.getElementById('root')}
    isOpen={isOpen}
    onAfterOpen={() => {
      document.body.style.overflow = 'hidden'
      afterOpenModal()
    }}
    onRequestClose={() => {
      document.body.style.overflow = 'unset'
      onRequestClose()
    }}
    style={customStyles}
  >

    <div style={{ float: 'right', marginTop: '-10px', marginRight: '-10px' }}>
      <IconButton
        aria-label='close'
        onClick={() => onRequestClose()}
      >
        <CloseIcon />
      </IconButton>
    </div>

    <ContentBox>
      {children}
    </ContentBox>
  </ModalBase>

export const forwardedModal = React.forwardRef(Modal)
