import styled from 'styled-components'
import React from 'react'

const StyledOption = styled.option`
    background: white;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: normal;
    outline: none;
    padding: 3px;
    width: 100%;    
`
// :hover {
//   background-color: #999;
//   font-color: white;
// }
const Option = ({ ...rest }, ref) => {
  return (
    <StyledOption
      {...rest}
      ref={ref}
    />
  )
}

export const ForwardedOption = React.forwardRef(Option)
