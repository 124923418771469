import styled from 'styled-components'
import React from 'react'

const StyledDropdown = styled.select`
    border: 2px solid #ccc;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: bold;
    outline: none;
    padding: 16px;
    width: 100%;

    ::placeholder {
        color: #bababa;
    }
`

const Dropdown = ({ ...rest }, ref) => {
  return (
    <StyledDropdown
      {...rest}
      ref={ref}
    />
  )
}

export const ForwardedDropdown = React.forwardRef(Dropdown)
