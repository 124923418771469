import React from 'react'
import { styled } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

const ValidationTextInput = styled(TextField)({
  width: '100%',
  margin: '4px 0px 0px 0px',
  '& input:valid + fieldset': {
    borderColor: 'green',
    borderWidth: 1
  },
  '& input:invalid + fieldset': {
    borderColor: 'red',
    borderWidth: 1
  },
  '& input:valid:focus + fieldset': {
    borderLeftWidth: 6,
    padding: '4px !important' // override inline-style
  }
})

const ValidTextInput = ({ ...rest }, ref) => {
  return (
    <ValidationTextInput
      InputLabelProps={{ required: false }}
      type='text'
      {...rest}
      ref={ref}
    />
  )
}

export const ValidationTextField = React.forwardRef(ValidTextInput)
